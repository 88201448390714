import React from 'react'

import {
  CardWrapper,
  CardHeader,
  CardImageWrapper,
} from '@styles/components/card'

const Card = ({ title, subtitle, image, status, details, children }) => {
  return (
    <CardWrapper>
      <CardHeader>
        <CardImageWrapper>
          <img src={image} alt={title} />
        </CardImageWrapper>
        <div>
          <h2>{title}</h2>
          <h4>{subtitle}</h4>
          <div>{details}</div>
        </div>
      </CardHeader>
      <div>{children}</div>
    </CardWrapper>
  )
}

export default Card
