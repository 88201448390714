import styled from 'styled-components'

const HomeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const HomeContainer = styled.div`
  max-width: 500px;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
`

export { HomeWrapper, HomeContainer }
