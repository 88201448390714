import React from 'react'
import { graphql } from 'gatsby'

import Card from '@components/card'
import Links from '@components/links'
import Layout from '@components/layout'
import SEO from '@components/seo'

import { HomeWrapper, HomeContainer } from '@styles/pages/home'

const IndexPage = ({ data }) => {
  const { avatarUrl, bio, isHireable, login, url } = data.github.viewer

  return (
    <Layout>
      <SEO title="Home" />
      <HomeWrapper>
        <HomeContainer>
          <Card
            title="Arturo Alviar"
            subtitle={
              <p>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  @{login}
                </a>
              </p>
            }
            details={
              <div>
                <p>{bio}</p>
                <p>
                  {isHireable
                    ? 'Currently looking for work!'
                    : 'Not looking but say hey!'}{' '}
                  👋🏽
                </p>
              </div>
            }
            image={avatarUrl}
          >
            <Links />
          </Card>
        </HomeContainer>
      </HomeWrapper>
    </Layout>
  )
}

export const githubViewerQuery = graphql`
  query githubViewerQuery {
    github {
      viewer {
        avatarUrl
        bio
        createdAt
        isHireable
        login
        url
      }
    }
  }
`

export default IndexPage
