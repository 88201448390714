import styled from 'styled-components'

const LinkIcon = styled.li`
  position: relative;
  display: inline-flex;
  margin-right: 1rem;
  a {
    font-size: 1.15rem;
    padding: 1rem;
  }
`

const LinksWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
`

export { LinksWrapper, LinkIcon }
