import styled from 'styled-components'

const imageSize = '128px'

const CardWrapper = styled.div`
  border-radius: 30px;
  background: ${props => props.theme.colors.primary};
  box-shadow: 15px 15px 30px #ebeff2, -15px -15px 30px #f9fdff;
  padding: 1.65rem;
  p {
    font-size: 0.85rem;
    line-height: 1;
    margin: 0;
    margin-bottom: 0.85rem;
  }
`

const CardImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  img {
    border-radius: 50%;
    max-width: ${imageSize};
  }
`

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: ${imageSize} 1fr;
  grid-gap: 2rem;
  h2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    margin-bottom: 0.65rem;
  }
  h4 {
    margin: 0;
    font-weight: 600;
  }
`

export { CardWrapper, CardHeader, CardImageWrapper }
