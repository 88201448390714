import React from 'react'
import { FiLink } from 'react-icons/fi'
import {
  FaGithub,
  FaCodepen,
  FaLinkedinIn,
  FaFreeCodeCamp,
} from 'react-icons/fa'
import TeamTreehouseIcon from '../images/teamtreehouse.svg'

import { LinksWrapper, LinkIcon } from '@styles/components/links'

const links = [
  {
    label: 'github',
    href: 'https://github.com/arturoalviar',
    renderIcon: () => <FaGithub />,
  },
  {
    label: 'codepen',
    href: 'https://codepen.io/arturoalviar',
    renderIcon: () => <FaCodepen />,
  },
  {
    label: 'linkedin',
    href: 'https://www.linkedin.com/in/arturoalviar/',
    renderIcon: () => <FaLinkedinIn />,
  },
  {
    label: 'freecodecamp',
    href: 'https://www.freecodecamp.org/arturoalviar',
    renderIcon: () => <FaFreeCodeCamp />,
  },
  {
    label: 'team treehouse',
    href: 'https://teamtreehouse.com/arturoalviar',
    renderIcon: () => <TeamTreehouseIcon />,
  },
  {
    label: 'arturoalviar',
    href: 'https://arturoalviar.com/',
    renderIcon: () => <FiLink />,
  },
]

const Links = () => {
  return (
    <LinksWrapper>
      {links.map((link, index) => {
        return (
          <LinkIcon>
            <a
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              title={link.label}
            >
              {link.renderIcon()}
            </a>
          </LinkIcon>
        )
      })}
    </LinksWrapper>
  )
}

export default Links
